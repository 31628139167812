import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import VueGtag from "vue-gtag";
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import VueSocialSharing from 'vue-social-sharing'
import shareIt from 'vue-share-it';
import Meta from "vue-meta";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF, faTwitter,  faLinkedin, faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faShare, faInfo, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faFacebookF, faTwitter, faLinkedin, faWhatsapp, faEnvelope, faShare, faInfo, faTrash)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(VueSocialSharing)
Vue.use(shareIt)
Vue.use(Meta);

Vue.use(VueGtag, {
  config: { id: "UA-65571678-2" },
  bootstrap: false
}, router);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');