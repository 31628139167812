/* eslint-disable */
const KUMSCHO_SERVER='https://kumscho.com'
const KUMSCHO_PRETIX_SERVER='https://tickets.kumscho.com'
const KUMSCHO_PRETIX_APITOKEN='asdf'

//const KUMSCHO_API_SERVER='http://localhost:3000'
const KUMSCHO_API_SERVER='https://api.kumscho.com'

export {
    KUMSCHO_SERVER,
    KUMSCHO_PRETIX_SERVER,
    KUMSCHO_API_SERVER,
    KUMSCHO_PRETIX_APITOKEN
}
