<template>

<v-container fluid class="pa-0">

  <v-card
      class="mx-auto pt-16 px-10"
      align="center"
      elevation="0"
  >
        <h1 v-if="getDisplaySize() !== 'xs'" class="mb-2" style="max-width: 400px"><span style="font-weight: 300">{{ $t('home.title') }}</span> {{$t('home.title_bold' )}}</h1>
        <h1 v-if="getDisplaySize() === 'xs'" class="mb-2" style="max-width: 400px; font-size: 29px"><span style="font-weight: 300">{{ $t('home.title') }}</span> {{$t('home.title_bold' )}}</h1>
        <div class="body-2" style="max-width: 300px">{{ $t('home.subtitle') }}</div>
  </v-card>
  <div class="mt-4">
    <Events class="pa-4"></Events>
  </div>



    <v-row align="center" class="py-16 px-2 my-16 mx-0" style="max-width: 100vw;">
      <v-col :align="alignColumnLeft()">
        <v-img
            max-width="400px"
            src="@/assets/newsletter.webp"
            contain
            rounded
            class="rounded mx-8"
        >
        </v-img>
      </v-col>
      <v-col :align="alignColumnRight()">
        <div style="max-width: 300px" class="mx-8">
          <h2 class="pb-2">{{ $t('newsletter.title') }}</h2>
          <div class="body-2 mb-4">{{ $t('newsletter.description') }}</div>

          <!-- Begin Mailchimp Signup Form -->
          <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css">

          <div id="mc_embed_signup">
            <form action="https://kumscho.us18.list-manage.com/subscribe/post?u=9ff8c44e0904e4a7c6a7ef3a4&amp;id=cd0946a6ef" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
              <div id="mc_embed_signup_scroll">

                <div class="mc-field-group">
                  <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" placeholder="E-Mail">
                </div>
                <div id="mce-responses" class="clear">
                  <div class="response" id="mce-error-response" style="display:none"></div>
                  <div class="response" id="mce-success-response" style="display:none"></div>
                </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_9ff8c44e0904e4a7c6a7ef3a4_cd0946a6ef" tabindex="-1" value=""></div>
                <div class="clear" :align="alignColumnRight()"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button primary"></div>
              </div>
            </form>
          </div>

          <!--End mc_embed_signup-->


        </div>
      </v-col>
    </v-row>

</v-container>
  
</template>

<script>
import Events from '../components/Events'

export default {
  components: {
    Events
  },
  methods: {
    getDisplaySize(){
      return this.$vuetify.breakpoint.name;
    },

    alignColumnLeft () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return "center"
        case 'sm': return "center"
        case 'md': return "right"
        case 'lg': return "right"
        case 'xl': return "right"
      }
    },
    alignColumnRight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return "center"
        case 'sm': return "center"
        case 'md': return "left"
        case 'lg': return "left"
        case 'xl': return "left"
      }
    },
  }
}
</script>

<style scoped>
#mc_embed_signup{background:#fff; clear:left; font:14px Roboto,Arial,sans-serif; }
/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
#mc_embed_signup form{padding: 0}

.big-header-div {
  padding-top: 100px;
  padding-bottom: 100px;
}
</style>