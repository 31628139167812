<template>
  <div id="app">
    <app-layout></app-layout>
  </div>
</template>

<script>
import AppLayout from './views/layouts/AppLayout'

export default {
  name: 'App',
  metaInfo() {
    return {
      title: this.$t('meta.title'),
      meta: [
        { name: 'description', content:  this.$t('meta.description')},
        { property: 'og:title', content: this.$t('meta.og:title')},
        { property: 'og:site_name', content: this.$t('meta.og:site_name')},
        {property: 'og:type', content: 'website'}
      ]
    }
  },
  components: {
    AppLayout
    //Loading
  },
  data: () => ({
    //
  })
};
</script>

<style lang="sass">
  @import '../node_modules/typeface-roboto/index.css'
</style>