<template>
  <v-footer
      color="grey lighten-3"
      padless
      light
    >
    <cookie-law position="bottom" :buttonText="$t('cookie.button')" theme="base" v-on:accept="enableCookies()" class="px-8">
      <div slot="message" class="body-1">
        {{ $t('cookie.message') }} <router-link to="/privacy">{{ $t('cookie.link') }}</router-link>
      </div>
    </cookie-law>
      <v-row
        justify="center"
        no-gutters
        v-if="this.$route.name !== 'CreateEvent'"
      >
          <div
              v-for="route in routes"
              :key="route.title"
          >
            <v-btn
                :to="route"
                text
                class="my-2 mr-2"
            >
              {{ $t('footer.' + route.title) }}
            </v-btn>
        </div>


        <v-col
          class="grey lighten-2 py-4 text-center"
          cols="12"
        >
          {{ new Date().getFullYear() }} — <strong>KUMSCHO</strong>
        </v-col>
      </v-row>
    </v-footer>
</template>

<script>
import CookieLaw from 'vue-cookie-law';
import { bootstrap } from 'vue-gtag'

export default {
  name: 'Footerbar',
  components: {
    CookieLaw
  },
  data: () => ({
        links: [
        'Events',
        'Über uns',
        'Kontakt',
        'Impressum',
        'AGB'
      ],
    }),
  methods: {
    enableCookies (){
      bootstrap().then(() => {});
    }
  },
  computed: {
      routes() {
        let routes = [];
        for (let i in this.$router.options.routes) {
          // eslint-disable-next-line no-prototype-builtins
          if (!this.$router.options.routes.hasOwnProperty(i)){
            continue
          }
          let route = this.$router.options.routes[i];
          // eslint-disable-next-line no-prototype-builtins
          if(route.hasOwnProperty('secondary') || route.hasOwnProperty('primary')) {
            routes.push(route);
          }
        }
        return routes;
      }
    }
}
</script>

<style>
.Cookie>* {
  margin: 0 !important;
  padding-bottom: 10px;
}

.Cookie--dark-lime .Cookie__button {
  background: #ff8a65;
}

.Cookie--base .Cookie__button{
  background: #f06292;
  border-radius: 4px;
}
</style>
