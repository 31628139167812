<template>
  <v-container class="event-list-container mb-8" grid-list-md>
      <v-layout row wrap>
        <v-flex d-flex xs12 sm6 md4  v-for="event in paginatedEvents" :key="event.id">
          <v-card v-on:click="navToEvent(event.slug)" class="my-4 d-flex flex-column" elevation="1" >
              <v-img
                height="250"
                :src="pretixServer + '/' + event.logo_imageurl"
                class="mb-2"
              ></v-img>

              <v-card-title class="mx-4">{{getLangName(event.name)}}</v-card-title>

              <v-card-text>

                <v-row
                  class="mx-4"
                  align="center"
                >
                  <h5 class="float-right orange lighten-5 px-2 mt-2 rounded" v-if="event.over">{{ $t('event.over_text') }}</h5>
                  <div class="body-2 my-4" v-if="event.list_text" v-html="event.list_text.substring(0, 150)"></div>
                </v-row>

                <v-chip-group
                    class="mx-4 my-4"
                    column
                  >
                    <v-chip small>
                      {{getFormatedDate(event.date_from)}}
                    </v-chip>
                    <v-chip small>
                      {{getLangLocation(event.location)}}
                    </v-chip>
                </v-chip-group>

                <v-spacer></v-spacer>

                <v-row
                  class="mx-4"
                >
                    <div style="width: 100%">
                      <div class="progress" v-if="event.funding_type && !event.has_subevents">
                          <div class="progress-bar" v-bind:style="{ width: event.progressWidthSize + '%'}"></div>
                      </div>

                      <div class="float-left" v-if="event.funding_type && !event.has_subevents"><b>{{getFundPercentage(event)}}% {{$t('event.reached')}}</b></div>
                      <div class="float-right" v-if="!event.over"><b>{{getDaysToGo(event.date_from)}} {{ $t('event.togo') }}</b></div>

                    </div>
                </v-row>
                <v-row
                    class="mx-4 mb-4"
                >
                  <div class="body-2 float-left" v-if="event.funding_type && !event.has_subevents">{{event.ticket_count}} {{$t('event.participants')}}</div>
                </v-row>
              </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    <div class="text-center">
      <v-pagination
          v-model="page"
          :length="getPaginationLenght()"
          @input="setPaginatedEvents(page * 6)"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
  import * as api from "@/api/apiserver";
  import * as config from '@/api/config';
  import * as helpers from "@/utils/helpers";

  export default {
    name: 'Events',
    data: () => (
        {
          events: [],
          pastEvents: [],
          allEvents: [],
          value: 45,
          max: 100,
          page: 1,
          paginatedEvents: []
        }
    ),
    computed: {
      pretixServer() {
        return config.KUMSCHO_PRETIX_SERVER
      },
    },
    methods: {
      async getEvents() {
        this.events = await api.getEvents();
        //remove events in testmode
        let index = this.events.length - 1;
        while (index >= 0){
          if (this.events[index].testmode) {
            this.events.splice(index, 1);
          }
          index -= 1;
        }

        this.events.forEach(event => {
          if (event.testmode) {
            let index = this.events.indexOf(event);
            if (index !== -1) {
              this.events.splice(index, 1);
            }
          }
        });
        //get events that already passed
        this.pastEvents = await api.getPastEvents();
        this.pastEvents.forEach(event => {
          event.over = true;
          this.events.push(event);
        });
        this.events.forEach(event => {
          this.setProgressWidthSize(event);
        });
        this.setPaginatedEvents(6);
      },

      getPaginationLenght(){
        return Math.ceil(this.events.length / 6);
      },

      setPaginatedEvents(n){
        this.paginatedEvents = this.events.slice(n - 6, n);
      },

      getLangName(item) {
        return helpers.getLangName(item)
      },

      getLangLocation(item) {
        return helpers.getLangLocation(item)
      },

      getFormatedDate(value){
        return helpers.getFormatedDate(value)
      },

      getDaysToGo(value){
        return helpers.getDaysToGo(value)
      },

      navToEvent(slug){
        this.$router.push({ name: 'event', params: { slug: slug } });
        // Send a "Played song" event to Mixpanel
        // with a property "genre"
        window.analytics.track('Navigated to Event Details')
      },

      getFundPercentage(event){
        if (event.funding_type === "ticketcount") {
          event.total_amount = event.ticket_count;
        }

        this.setProgressWidthSize(event);
        if (event.total_amount > 0) {
          return Math.round(event.total_amount / event.min_amount * 100 * 100) / 100;
        }
        return 0;
      },

      setProgressWidthSize(event){
        if (event.funding_type === "ticketcount") {
          event.total_amount = event.ticket_count;
        }

          let percentage = event.total_amount / event.min_amount * 100;
          if (percentage > 100) {
            event.progressWidthSize = 100;
          } else if (percentage <= 0) {
            event.progressWidthSize = 0;
          } else {
            event.progressWidthSize = percentage;
          }
      }
    },
    mounted() {
      window.analytics.track('Visited Event List Page')
      this.getEvents();
    }
  }
</script>

<style scoped>
.event-list-container {
  max-width: 1280px;
}

.progress {
    height: 10px;
    width: 100%;
    background-color: #eee;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 4px;
    margin-top: 12px;
    margin-bottom: 12px;
}

.progress-bar {
  height: 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 4px;
  float: left;
  /*background: linear-gradient(to right, #f2709c 0%, #ff9472 100%);*/
  background: #00C853;
}

.v-card__title {
  word-break: normal !important;
}

</style>