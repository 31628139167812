<template>
    <div class="overflow-hidden">
    <v-app-bar
        flat
        color="white"
        class="header-nav-bar"
    >
      <router-link to="/">
        <v-img
            alt="KUMSCHO Logo"
            class="shrink mr-2"
            contain
            src="@/assets/kumscho_logo.png"
            transition="scale-transition"
            width="100"
        />
      </router-link>

      <v-spacer></v-spacer>

      <Menu class="menu-bar" v-if="this.$route.name !== 'CreateEvent'"></Menu>
      <!--        target="_blank"-->
      <v-btn
          v-if="this.$route.name !== 'CreateEvent'"
          href="/createevent"
          color="secondary"
          elevation="0"
          class="create-event-btn"
          rounded
          style="text-transform: none; font-weight: normal"
      >
        {{ $t('toolbar.create_event') }}
      </v-btn>
      <v-btn
          v-if="this.$route.name === 'CreateEvent'"
          href="/contact"
          color="secondary"
          elevation="0"
          rounded
          outlined
          style="text-transform: none; font-weight: normal"
      >
        {{ $t('toolbar.get_help') }}
      </v-btn>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="nav-icon" v-if="this.$route.name !== 'CreateEvent'"></v-app-bar-nav-icon>

    </v-app-bar>

    <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        class="px-2 pt-6"
    >
      <h1 class="mb-2 mx-4">{{ $t('toolbar.menu_title') }}</h1>
      <v-list
          nav
          dense
      >
          <v-list-item
              v-for="route in routes"
              :key="route.title"
              :to="route"
              link
          >
            <v-list-item-title class="px-2">{{ $t('toolbar.' + route.title) }}</v-list-item-title>
          </v-list-item>
      </v-list>
      <template>
        <div class="pa-2">
          <v-btn
              block
              color="secondary"
              elevation="0"
              to="/createevent"
          >
            {{ $t('toolbar.create_event_short') }}
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </div>

</template>

<script>
import Menu from '../../components/Menu.vue'

export default {
  name: 'Toolbar',
  components: {
    Menu
  },
  data: () => ({
    drawer: false,
    group: null,
  }),

  watch: {
    group () {
      this.drawer = false
    },
  },

  computed: {
    routes() {
      let routes = [];
      for (let i in this.$router.options.routes) {
        // eslint-disable-next-line no-prototype-builtins
        if (!this.$router.options.routes.hasOwnProperty(i)){
          continue
        }
        let route = this.$router.options.routes[i];
        // eslint-disable-next-line no-prototype-builtins
        if(route.hasOwnProperty('title') && route.hasOwnProperty('primary')) {
          routes.push(route);
        }

      }
      return routes;
    }
  }
}
</script>

<style scoped>
.logo {
  margin-left: 12px;
}
.menu {
  text-transform: none;
  line-height: inherit;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  padding: 5px 13px;
}
.nav-icon{
  display: none !important;
}

@media (max-width: 1024px) {
 .menu-bar {
  display: none !important;
 }
  .header-nav-bar {
    padding-top: 10px;
  }
  .nav-icon{
    display: block !important;
  }
  .create-event-btn{
    display: none !important;
  }
}
</style>
