import moment from "moment";
import router from '../router/index'

const removeHtml = (items) => {
    let regex = /(<([^>]+)>)/ig
    for (var key in items) {
        items[key] = String(items[key]).replace(regex, "");
    }
    return items
}

const generateFormData = (form) => {
    const formData = new FormData();
    //const arr = []
    for (const [key, value] of Object.entries(form)) {
        //let regex = /(<([^>]+)>)/ig;

        if(Object.prototype.toString.call(value) === "[object File]") {
            formData.append(key, value)
        }
        else
        if(Object.prototype.toString.call(value) === "[object Array]") {
            // eslint-disable-next-line no-unused-vars
            for (const [k, v] of Object.entries(value)) {
                let s = JSON.stringify(v)
                //let s = JSON.stringify(v).replace(regex, "")
                //arr.push(s)
                formData.append(key + '[]', s)
            }

        }
        else
            //formData.append(key, String(value).replace(regex, ""));
            formData.append(key, String(value));
    }

    // for (const [key, value] of Object.entries(ticketTypes)) {
    //     formData.append(key, value);
    // }

    return formData
}

const getLangName = (item)  => {
    item = JSON.parse(item)
    return item.de  //language ???
}

const getLangText = (item)  => {
    item = JSON.parse(item)
    return item.de  //language ???
}

const getLangLocation = (item)  => {
    item = JSON.parse(item)
    return item.de  //language ???
}

const getFormatedDate = (value) => {
    return moment(String(value)).locale(router.app.$i18n.locale).format('DD.MM.YYYY, HH:mm') + " Uhr"
}

const getDaysToGo = (value) => {
    return moment(String(value)).locale(router.app.$i18n.locale).fromNow(true);
}

const formatDate = (date) => {
    if (!date) return null

    const [year, month, day] = date.split('-')
    return `${day}.${month}.${year}`
}



export {
    removeHtml,
    generateFormData,
    getLangName,
    getLangLocation,
    getFormatedDate,
    getDaysToGo,
    formatDate,
    getLangText
}