import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    title: 'events',
    component: Home,
  },
  {
    path: '/create',
    name: 'SendEvent',
    title: 'Send Event',
    component: () => import(/* webpackChunkName: "CreateEvent" */'../views/CreateEvent.vue'),
  },
  {
    path: '/createevent',
    name: 'CreateEvent',
    title: 'create_event',
    component: () => import(/* webpackChunkName: "CreateEvent" */'../views/CreateEvent.vue'),
  },
  {
    path: '/confirm',
    name: 'EventConfirmation',
    title: 'event_confirmation',
    component: () => import(/* webpackChunkName: "EventConfirmation" */'../components/EventConfirmation.vue'),
    props: true
  },
  {
    path: '/',
    name: 'Events',
    title: 'events',
    component: Home,
    primary: 1
  },
  {
    path: '/event/:slug',
    name: 'event',
    component: () => import(/* webpackChunkName: "EventDetail" */'../views/EventDetail.vue'),
  },
  {
    path: '/features',
    name: 'Features',
    title: 'features',
    component: () => import(/* webpackChunkName: "Features" */'../views/Features.vue'),
    primary: 1
  },
  {
    path: '/prices',
    name: 'Prices',
    title: 'prices',
    component: () => import(/* webpackChunkName: "Prices" */'../views/Prices.vue'),
    primary: 1
  },
  {
    path: '/imprint',
    name: 'Imprint',
    title: 'imprint',
    component: () => import(/* webpackChunkName: "Imprint" */'../views/Imprint.vue'),
    secondary: 1
  },
  {
    path: '/agb',
    name: 'AGB',
    title: 'agb',
    component: () => import(/* webpackChunkName: "Terms" */'../views/Terms.vue'),
    secondary: 1
  },
  {
    path: '/privacy',
    name: 'Privacy',
    title: 'privacy',
    component: () => import(/* webpackChunkName: "DataPrivacy" */'../views/DataPrivacy.vue'),
    secondary: 1
  },
  {
    path: '/contact',
    name: 'Contact',
    title: 'contact',
    component: () => import(/* webpackChunkName: "Contact" */'../views/Contact.vue'),
    primary: 1
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  mode: 'history'
})

export default router
