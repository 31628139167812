import axios from 'axios'
import * as config from '@/api/config'

const API_HEADERS = {'Content-Type': 'application/json'}


const getItems = (type, params = {}) => {
    try {
        let url = config.KUMSCHO_API_SERVER + '/' + type
        return axios.get(url, {headers: API_HEADERS, params: params})
    } catch (error) {
        console.error(error)
    }
}

const getEvents = async () => {
    let items =  await getItems('events', {});
    return items.data
}
const getPastEvents = async () => {
    let items =  await getItems('pastevents', {});
    return items.data
}


const getEventsBySlug = async (slug) => {
    let items =  await getItems('events/' + slug, {});
    return items.data
}
const getPastEventsBySlug = async (slug) => {
    let items =  await getItems('pastevents/' + slug, {});
    return items.data
}

const getSubEvents = async (id) => {
    let items =  await getItems('subevents/' + id, {});
    return items.data
}



export {
    getEvents,
    getEventsBySlug,
    getPastEvents,
    getPastEventsBySlug,
    getSubEvents
}
