<template>
  <div>
<!--    <div class="hidden-sm-and-down">-->
    <a href="https://locations.kumscho.com" target="_blank" class="text-decoration-none">
      <v-btn
          text
          class="my-2 mr-2"
          style="text-transform: none"
      >
        {{ $t('toolbar.location') }}
      </v-btn>
    </a>

        <v-btn
          v-for="route in routes"
          :key="route.title" 
          :to="route"
          text
          class="my-2 mr-2"
          style="text-transform: none"
        >
          {{ $t('toolbar.' + route.title) }}
        </v-btn>

    <!--    <v-menu class="hidden-md-and-up">-->
<!--      <template v-slot:activator="{ on }">-->
<!--        <v-btn icon v-on="on" class="hidden-md-and-up">-->
<!--          <v-icon>menu</v-icon>-->
<!--        </v-btn>-->
<!--      </template>-->
<!--      <v-list>-->
<!--        <v-list-item v-for="item in menu" :key="item.id" :to="getLink(item.link)">-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title> {{ translate(item.i18n).label }}</v-list-item-title>-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->
<!--      </v-list>-->
<!--    </v-menu>-->
  </div>
</template>

<script>

export default {
  name: "Menu",
  computed: {
      routes() {
        let routes = [];
        for (let i in this.$router.options.routes) {
          // eslint-disable-next-line no-prototype-builtins
          if (!this.$router.options.routes.hasOwnProperty(i)){
            continue
          }
          let route = this.$router.options.routes[i];
          // eslint-disable-next-line no-prototype-builtins
          if(route.hasOwnProperty('title') && route.hasOwnProperty('primary')) {
            routes.push(route);
          }
    
        }
        return routes;
      }
    }
}
</script>

<style scoped>
.menu {
  text-transform: none;
  line-height: inherit;
  /*font-size: 14px;*/
  color: rgba(0, 0, 0, 0.87);
  float: left;
  padding: 5px 13px;
}

</style>
